<template>
  <v-select
    v-model="input"
    :hint="hint"
    :items="packages"
    :menu-props="{ maxHeight:'270' }"
    :rules="[ () => (!!input && !!input.code) || 'Package required!' ]"
    dense
    item-text="name"
    item-value="description"
    label="Select Ifinancing package"
    required
    box
    return-object
    persistent-hint
    validate-on-blur
  >
    <template
      slot="item"
      slot-scope="data"
    >
      <v-list-tile-content>
        <v-list-tile-title v-html="data.item.name" />
        <v-list-tile-sub-title v-html="data.item.description" />
      </v-list-tile-content>
    </template>
  </v-select>
</template>

<script>
import { createGetParams } from '@/rest'

export default {
  name: 'IfinancingPackages',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      hint: 'Package required',
      input: null,
      packages: [],
    }
  },
  watch: {
    input: function (val) {
      if (val) {
        this.input !== this.value && this.$emit('input', val)
        this.hint = val.description
      }
    },
    value: function (val) {
      if (val) this.input = val
    },
  },
  mounted: function () {
    this.getPackages()
    if (this.value) this.input = this.value
  },
  methods: {
    getPackages: function () {
      this.$rest.get('getIfinancingPackages.php', createGetParams({}))
        .then(function (response) {
          for (const item of response.data) {
            this.packages.push(item)
          }
        }.bind(this)).catch(() => {
          // (error)
        })
    },
  },
}
</script>
